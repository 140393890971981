import { render, staticRenderFns } from "./authUser.vue?vue&type=template&id=a25b8774"
import script from "./authUser.vue?vue&type=script&lang=js"
export * from "./authUser.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\git_owner\\ecgame\\ec-game-manage\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a25b8774')) {
      api.createRecord('a25b8774', component.options)
    } else {
      api.reload('a25b8774', component.options)
    }
    module.hot.accept("./authUser.vue?vue&type=template&id=a25b8774", function () {
      api.rerender('a25b8774', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/system/role/authUser.vue"
export default component.exports