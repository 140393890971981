<template>
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!topNav"/>
    <top-nav id="topmenu-container" class="topmenu-container" v-if="topNav"/>

    <el-row class="right-menu">
      <el-col :span="3">
        <div @click="trunOrderPage">
          <el-dropdown trigger="hover">
            <span class="el-icon-bell el-icon-bell-large" :class="{'has-unread': unreadCount > 0}">
              <span v-if="unreadCount > 0" class="unread-count">{{ unreadCount }}</span>
            </span>
            <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
              <el-dropdown-item class="custom-dropdown-menu-item" v-for="(reminder, index) in reminderList" :key="index">
                {{ reminder.messageContent }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
      <el-col :span="8" style="display:none">
      <div><span style="background-color:#F5F7FA;font-size: 20px ;width: 30px">{{hours}} </span>
        <span>: </span>
        <span style="background-color:#F5F7FA;font-size: 20px ;width: 30px">{{minutes}} </span>
        <span>: </span>
        <span style="background-color:#F5F7FA;font-size: 20px ;width: 30px">{{seconds}} </span>
      </div>
      </el-col>
      <el-col :span="8" >
        <span style="font-size: 15px;margin-left: 5px">{{ info.nickName }}</span>
      </el-col>

      <el-col :span="8">
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
        <div class="avatar-wrapper">
          <img :src="avatar" class="user-avatar">
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/user/profile">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <el-dropdown-item @click.native="setting = true">
            <span>布局设置</span>
          </el-dropdown-item>
          <el-dropdown-item divided @click.native="logout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import SizeSelect from '@/components/SizeSelect'
import Search from '@/components/HeaderSearch'
import {getInfo} from '../../api/login'
import store from "@/store";

export default {
  components: {
    Breadcrumb,
    TopNav,
    Hamburger,
    Screenfull,
    SizeSelect,
    Search,
  },
  data(){
    return{
      info:{},
      hours: "",
      minutes: "",
      seconds: "",
      unreadCount: 0, 
      reminderList: [],
      sseSource: null
    }
  },
  created() {
    const tmpgetCustomerMessages = this.getCustomerMessages;
    // 创建新的 EventSource 连接到 /sseConnect 路由
    this.sseSource = new EventSource(`${process.env.VUE_APP_BASE_API}/sseConnect/${store.getters.id}`);
    // 监听消息事件
    this.sseSource.addEventListener("management_notice:",function(event){
        // tmpshowNotification(event.data);
        tmpgetCustomerMessages();
    });
    // this.sseSource.onmessage = (event) => {
    //     console.log(event.data);
    // };
    // 监听连接打开事件
    this.sseSource.onopen = () => {
      console.log('SSE 连接已打开');
    };
    // 监听连接错误事件
    this.sseSource.onerror = (error) => {
      console.log('SSE 连接出错:', error);
    };
  },
  beforeDestroy() {
    // 在组件销毁前关闭 SSE 连接
    if (this.sseSource) {
      this.sseSource.close();
    }
  },
  mounted(){
    this.getInfo();
    this.getNowTime();//进入页面调用该方法获取当前时间
    clearInterval(myTimeDisplay );//销毁之前定时器
    var myTimeDisplay = setInterval(() => {
      this.getNowTime(); //每秒更新一次时间
    }, 1000);
    this.getCustomerMessages();
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'avatar',
      'device'
    ]),
    setting: {
      get() {
        return this.$store.state.settings.showSettings
      },
      set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'showSettings',
          value: val
        })
      }
    },
    topNav: {
      get() {
        return this.$store.state.settings.topNav
      }
    }
  },
  methods: {
    getCustomerMessages(){
      this.get("/customerMessages/list?pageIndex=1&pageSize=10000&sortOptions=%7B%22createTime%22%3A%22desc%22%7D&isRead=0").then(res => {
        this.reminderList = res.data.list;
        this.unreadCount = res.data.totalRows;
      });
    },
    showNotificationMsg() {
        new Notification('新消息提醒', 
            { title: '订单提醒', body: '你有一条新的订单消息。', icon: '../../../assets/icons/favicon.png'}
        );
    },
    showNotification(){
      // 检查用户是否授权显示通知
      if ('Notification' in window && Notification.permission === 'granted') {
          this.showNotificationMsg()
      } else {
          // 请求用户授权显示通知
          Notification.requestPermission().then((permission) => {
              if (permission === 'granted') {
                  this.showNotificationMsg();
              }
          });
      }
    },
    trunOrderPage(){
      if(this.unreadCount == 0){
        return;
      }
      this.put(`/customerMessages/`,null).then(response => {
          if (response.status === 200) {
            console.log("客服消息已读");
            this.unreadCount = 0;
            this.reminderList = [];
          } else {
            console.error("客服消息已读失败");
          }
      }).catch(() => {
          console.error("客服消息已读失败");
      })
      this.$router.push({ path: `/salesManagement/orderManagement` });
    },
    getInfo(){
      getInfo().then(res=>{
        this.info=res.data

      })
    },
    getNowTime() {
      var date = new Date();
      var hours =  this.addZero(date.getHours() );
      var minutes = this.addZero(date.getMinutes());
      var seconds = this.addZero(date.getSeconds());
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
    },
    //根据自己的需求，看要不要在时间不大于10的时候在前面补0，如果需要直接this.addZero(date.getMinutes()),其它与之相同，如果不需要删掉addZero（）方法即可。
    //小于10的拼接上0字符串
    addZero(s) {
      return s < 10 ? ('0' + s) : s;
    },
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar')
    },
    async logout() {
      this.$confirm('确定注销并退出系统吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$store.dispatch('LogOut').then(() => {
          location.href = '/index';
        })
      }).catch(() => {});
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-dropdown-menu {
  max-height: 100px; 
  overflow-y: auto; 
  background-color: white; 
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  padding: 5px; 
  &:hover {
    background-color: white;
  }
}

.custom-dropdown-menu-item{
  &:hover {
    background-color: white;
    color:#606266
  }
  cursor: default;
}

.has-unread {
  position: relative;
}

.unread-count {
  position: absolute;
  top: -5px;
  right: -5px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 12px;
}

.el-icon-bell-large {
  font-size: 25px; 
  margin-top: 12px;
  cursor: pointer;
}

.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    width: 250px;
    float: right;
    height: 100%;
    line-height: 50px;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .avatar-container {
      margin-right: 30px;

      .avatar-wrapper {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
