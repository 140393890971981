var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t_upload_file", class: { hide_file: _vm.limitNum } },
    [
      _c(
        "el-upload",
        _vm._g(
          _vm._b(
            {
              ref: "upload",
              attrs: {
                action: _vm.savePath,
                headers: _vm.headers,
                multiple: _vm.multiple,
                "list-type": _vm.listType,
                "on-success": _vm.handleSuccess,
                "before-upload": _vm.beforeUpload,
                "on-remove": _vm.onRemove,
                "on-error": _vm.handleError,
                "on-progress": _vm.handleProgress,
                "on-preview": _vm.handlePreview,
                accept: _vm.fileType,
                disabled: _vm.disabled,
                "file-list": _vm.tempArr,
                "on-change": _vm.fileListChange,
                limit: _vm.totalLimit,
              },
            },
            "el-upload",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm.listType === "picture-card"
            ? _c(
                "div",
                {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "file",
                        fn: function (ref) {
                          var file = ref.file
                          return _c(
                            "div",
                            {
                              staticClass: "el-upload-list__item",
                              staticStyle: { position: "relative" },
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "el-upload-list__item-thumbnail pointer",
                                attrs: {
                                  src: _vm.downloadFixedIcon(file.name),
                                  alt: "",
                                },
                              }),
                              _c(
                                "span",
                                { staticClass: "el-upload-list__item-actions" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-preview",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-zoom-in",
                                      }),
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "el-upload-list__item-delete",
                                      on: {
                                        click: function ($event) {
                                          return _vm.downloadFileHandle(file.id)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-download",
                                      }),
                                    ]
                                  ),
                                  !_vm.disabled
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "el-upload-list__item-delete",
                                          on: {
                                            click: function ($event) {
                                              return _vm.onRemove(file)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-delete",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          )
                        },
                      },
                    ],
                    null,
                    false,
                    1962610848
                  ),
                },
                [
                  _c("i", {
                    staticClass: "el-icon-plus",
                    attrs: { slot: "default" },
                    slot: "default",
                  }),
                ]
              )
            : _vm._e(),
          _vm.rightTip
            ? _c(
                "div",
                {
                  staticClass: "right_tip",
                  style: {
                    left:
                      (_vm.totalLimit === 1
                        ? 1
                        : _vm.totalLimit - this.tempArr.length === 0
                        ? this.tempArr.length
                        : this.tempArr.length + 1) *
                        160 +
                      "px",
                  },
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [_vm._v(_vm._s(_vm.rightTip) + " ")]
              )
            : _vm._e(),
          _vm.isDrag && _vm.listType !== "picture-card"
            ? _c("div", [
                _c("i", {
                  staticClass: "el-icon-upload",
                  attrs: { slot: "default" },
                  slot: "default",
                }),
                _c("div", { staticClass: "el-upload__text" }, [
                  _vm._v(" 将文件拖到此处，或 "),
                  _c("em", [_vm._v("上传文件")]),
                ]),
              ])
            : _vm._e(),
          !_vm.isDrag && _vm.listType !== "picture-card"
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    disabled: _vm.btnDisabled,
                    icon: "el-icon-upload",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.buttonName) + " ")]
              )
            : _vm._e(),
          _vm.isShowTip
            ? _c(
                "div",
                {
                  staticClass: "el-upload__tip upload-file-tips",
                  attrs: { slot: "tip" },
                  slot: "tip",
                },
                [
                  _vm._v(
                    " 仅允许上传类型为" +
                      _vm._s(_vm.fileType) +
                      ",文件名不超过 " +
                      _vm._s(_vm.fileNameLimit) +
                      "位，一次上传文件数量不超过 " +
                      _vm._s(this.onceLimit) +
                      "个，并且不大于" +
                      _vm._s(_vm.limitSize) +
                      "MB的文件。 "
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "dialog_preview",
          attrs: {
            title: "预览图片",
            width: "40%",
            modal: false,
            visible: _vm.dialogVisible,
          },
          on: {
            close: _vm.closeDialog,
            "update:visible": function ($event) {
              _vm.dialogVisible = false
            },
          },
        },
        [
          _vm.showVideoUrl
            ? _c(
                "video",
                {
                  staticClass: "avatar video-avatar",
                  staticStyle: {
                    width: "100%",
                    height: "100%",
                    "object-fit": "fill",
                  },
                  attrs: { src: _vm.showVideoUrl, controls: "controls" },
                },
                [_vm._v(" 您的浏览器不支持视频播放 ")]
              )
            : _c("img", {
                attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
              }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }