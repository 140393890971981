<template>
  <div class="app-container">


    <Search :searchOpts="searchOpts" :listTypeInfo="listTypeInfo" @onSearch="onSearch" @onReset="onReset"/>


    <TableToolBar :url="url" :single="single" :multiple="multiple"
                  :handleDelete="handleDelete"
    />

    <div class="t-table" style="width:100%;">
      <t-table :table="table"
               isShowPagination
               :columns="columns"
               :listTypeInfo="listTypeInfo"
               @selection-change="selectionChange"
               @page-change="pageChange"
      >
      </t-table>
    </div>


    <t-dialog :title="formOpts.title" :visible.sync="open" width="950px" :isShowDialogDrag="true"  append-to-body>
      <t-form
        :ref-obj.sync="formOpts.ref"
        :formOpts="formOpts"
        :listTypeInfo="listTypeInfo"
        :widthSize="2"
      >
      </t-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </t-dialog>

  </div>
</template>
<script>
import { commonMixin } from '@/mixins'

export default {
  mixins: [commonMixin],
  name: "Newscomments",
  data() {
    return {
      title: '新闻评论管理',
      url: {
        list: '/newsComments/list/',
        getInfo: '/newsComments/',
        add: '/newsComments/',
        edit: '/newsComments/',
        del: '/newsComments/'
      },
      searchOpts: [
        { label: '创建时间', prop: 'createTime', element: 'el-date-picker' },
      ],
      listTypeInfo: {
        // categoryList: [],
      },
      sortOptions: {
        createTime: 'desc'
      },
      queryParams: {
        goodsId: null,
      },
      formOpts: {
        title: 'SKU信息',
        ref: null,
        labelWidth: '120px',
        formData: {
          id: null,
          nickName: null,
          reviewContent: null,
        },
        fieldList: [
          { label: '用户昵称', value: 'nickName', comp: 'el-input', widthSize: 1 },
          { label: '评价内容', value: 'reviewContent', type: 'number', comp: 'el-input', widthSize: 1},
        ],
        rules: {
          nickName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
          reviewContent: [{ required: true, message: '请输入通常价格', trigger: 'blur' }],
        }
      },
      table: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        data: [],
        firstColumn: { type: 'selection' },
        operator: [
          {
            text: '删除',
            fun: this.handleDelete
          }
        ]
      },
      columns: [
        { prop: 'id', label: 'ID', minWidth: '100'},
        { prop: 'nickName', label: '用户昵称', minWidth: '180'},
        { prop: 'commentContent', label: '评论内容', minWidth: '300' },
        { prop: 'createTime', label: '创建日期', minWidth: '220'}
      ]
    }
  },
  created() {
    const newsId = this.$route.params && this.$route.params.newsId;
    this.queryParams.newsId = newsId;
  },
  methods: {
  }
}
</script>
