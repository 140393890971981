import { delUndefined } from '@/utils/efeichong'
import { getToken } from '@/utils/auth'
import { Loading } from 'element-ui'

export const commonMixin = {
  data() {
    return {
      // 总条数
      total: 0,
      // 遮罩层
      loading: true,
      /* 数据源 */
      dataList: [],
      queryParams: {
        pageIndex: 1,
        pageSize: 10
      },
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      // 弹出层标题
      title: '',
      // 是否显示弹出层
      open: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 状态数据字典
      statusOptions: [],
      //上传
      upload: {
        // 是否显示弹出层（用户导入）
        open: false,
        // 弹出层标题（用户导入）
        title: '',
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的用户数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: getToken() }
      },
      loadingInstance: '',
      dateRange: [],
      sortOptions: {
      },
      formData: {},
      url: {
        list: '',
        getInfo: '',
        add: '',
        edit: '',
        del: ''
      },
      isUpdate: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    /** 搜索 */
    onSearch(val) {
      this.queryParams.pageIndex = 1
      this.table.currentPage = 1
      this.queryParams = delUndefined(val)
      this.getList()
    },
    /** 重置按钮操作 */
    onReset() {
      this.table.currentPage=1
      this.onSearch({})
    },
    /** 查询列表 */
    getList() {
      if (this.sortOptions) {
        this.queryParams.sortOptions = JSON.stringify(this.sortOptions)
      }
      //设置分页参数
      this.queryParams.pageIndex = this.table.currentPage
      this.queryParams.pageSize = this.table.pageSize
      this.loading = true
      this.dataList = []
      this.get(this.url.list, this.addDateRange(this.queryParams)).then(
        res => {
          this.table.data = res.data.list
          this.table.total = res.data.totalRows
          this.loading = false
        }
      )
    },
    pageChange(pageIndex, pageSize) {
      if (pageIndex != null) {
        this.queryParams.pageIndex = pageIndex
        this.table.currentPage=pageIndex
      }
      if (pageSize != null) {
        this.queryParams.pageSize = pageSize
        this.table.pageSize=pageSize
      }
      this.getList();

    },

    // 多选框选中数据
    selectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length != 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.bannerProjectId=null
      this.messageProjectId=null
      this.isUpdate = false
      this.reset()
      this.open = true
      this.title = '新增'
    },

    /** 修改按钮操作 */
    handleUpdate(row) {
      this.isUpdate = true
      this.reset()
      this.get(this.url.getInfo + row.id).then(response => {
        this.$data.formOpts.formData = response.data
        this.open = true;
        this.title = '修改'
      })

    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let _this = this;
      const ids = row.id ? [row.id] : this.ids
      this.$confirm('是否确认删除所选数据项?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        return _this.del(this.url.del, ids)
      }).then((res) => {
        if (res.status === 200) {
          this.getList()
          this.msgSuccess(res.message)
        } else {
          this.msgError(res.message)
        }
      }).catch(function() {
      })
    },
    //上传文件
    handleImport() {
      this.upload.open = true
    },
    //下载模板
    downloadModel() {
      this.download(this.url.downloadModel).then(response => {
        this.download(response, this.$store.getters.fileName)
      }).catch(error => {
        console.log(error)
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      if (this.sortOptions) {
        this.queryParams.sortOptions = JSON.stringify(this.sortOptions)
      }
      const queryParams = JSON.parse(JSON.stringify(this.queryParams))
      delete queryParams.pageIndex
      delete queryParams.pageSize
      this.$confirm('是否确认导出?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.download(this.url.export, queryParams)
      }).catch(function() {
      })
    },

    /** 提交按钮 */
    submitForm: function() {
      this.formOpts.ref.validate((valid) => {
        if (valid) {
          this.loadingInstance = Loading.service({
            lock: true,
            text: '正在提交...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.3)',
            target: '.sub-main',
            body: true,
            customClass: 'mask'
          })
          if (this.formOpts.formData.id !== null && this.formOpts.formData.id !== '' && this.formOpts.formData.id !== undefined) {
            this.put(this.url.edit, this.$data.formOpts.formData).then(response => {
              if (response.status === 200) {
                this.msgSuccess('修改成功')
                this.open = false
                this.getList()
              } else {
                this.msgError(response.message)
              }
              this.$nextTick(() => {
                this.loadingInstance.close()
              })
            }).catch(() => {
              this.$nextTick(() => {
                this.loadingInstance.close()
              })
            })
          } else {
            this.post(this.url.add, this.$data.formOpts.formData).then(response => {
              if (response.status === 200) {
                this.msgSuccess('新增成功')
                this.open = false
                this.getList()
              } else {
                this.msgError(response.message)
              }
              this.$nextTick(() => {
                this.loadingInstance.close()
              })
            }).catch(() => {
              this.$nextTick(() => {
                this.loadingInstance.close()
              })
            })
          }
        }
      })
    },

    // 表单重置
    reset() {
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data().formOpts.formData
      )
    },

    // 取消按钮
    cancel() {
      this.open = false
    }
  }
}
