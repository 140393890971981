var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t_layout" }, [
    _c("div", { ref: "section", staticClass: "section" }, [
      _vm.isShowTitle
        ? _c("header", { staticClass: "sectionTitle" }, [
            _vm._v(" " + _vm._s(_vm.sectionTitle) + " "),
            _c(
              "div",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("click")
                  },
                },
              },
              [
                _vm.returnBtn
                  ? _c("el-button", { attrs: { plain: "", size: "small" } }, [
                      _vm._v("返回"),
                    ])
                  : _vm._e(),
                _vm._t("head-right"),
              ],
              2
            ),
          ])
        : _vm._e(),
      _c(
        "div",
        { ref: "layoutContent", staticClass: "layout-content" },
        [
          _vm._t("default"),
          _c(
            "div",
            { staticClass: "flex-box flex-ver fix-btn" },
            [_vm._t("fixBtn")],
            2
          ),
          _vm.isShowFooter
            ? _c("div", { staticClass: "footer" }, [
                _c("div", [
                  _vm._v(
                    "**************有限公司版权所有 粤ICP备**********号-2"
                  ),
                ]),
                _c("div", { staticClass: "t-margin-top-5" }, [
                  _vm._v(
                    "©2017-2023 JIANDANH INFORMATION TECH (GUANGZHOU) CO., LTD All Rights Reserved."
                  ),
                ]),
              ])
            : _vm._e(),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }