<template>
  <div class="app-container">


    <Search :searchOpts="searchOpts" :listTypeInfo="listTypeInfo" @onSearch="onSearch" @onReset="onReset"/>


    <TableToolBar :url="url" :single="single" :multiple="multiple" :handleAdd="customhandleAdd"
                  :handleDelete="handleDelete"
    />

    <div class="t-table" style="width:100%;">
      <t-table :table="table"
               isShowPagination
               :columns="columns"
               :listTypeInfo="listTypeInfo"
               @selection-change="selectionChange"
               @page-change="pageChange"
      >
        <template #image="{scope}">
          <img :src="scope.row.image" width="50" height="50" />
        </template>
      </t-table>
    </div>


    <t-dialog :title="formOpts.title" :visible.sync="open" width="950px" :isShowDialogDrag="true"  append-to-body>
      <t-form
        :ref-obj.sync="formOpts.ref"
        :formOpts="formOpts"
        :listTypeInfo="listTypeInfo"
        :widthSize="2"
      >
       <template #image>
          <t-upload-file
            :totalLimit="1"
            fileType=".jpg,.jpeg,.png,.webp"
            :isPreview="true"
            :show-file-list="formOpts.formData.image!=null ?[formOpts.formData.image]:[]"
            @changeFileList="changeFileList"
            ref="upload-file"
          />
        </template>
      </t-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </t-dialog>

  </div>
</template>
<script>
import { commonMixin } from '@/mixins'

export default {
  mixins: [commonMixin],
  name: 'TagData',
  data() {
    return {
      title: '游戏标签管理',
      url: {
        list: '/tags/list/',
        getInfo: '/tags/',
        add: '/tags/',
        edit: '/tags/',
        del: '/tags/'
      },
      searchOpts: [
        { label: '标签名称', prop: 'tagName', element: 'el-input', likeType: 'contains' },
        { label: '创建时间', prop: 'createTime', element: 'el-date-picker' },
      ],
      listTypeInfo: {
        // categoryList: [],
      },
      sortOptions: {
        createTime: 'desc'
      },
      queryParams: {
        categoryId: null,
      },
      formOpts: {
        title: '标签信息',
        ref: null,
        labelWidth: '120px',
        formData: {
          id: null,
          tagName: null,
          categoryId: null,
          sortNo: null,
          image: null
        },
        fieldList: [
          // { label: '所属分类', value: 'categoryId', type: 'select-arr', comp: 'el-select', list: 'categoryList', widthSize: 1 },
          { label: '标签名称', value: 'tagName', comp: 'el-input', widthSize: 1 },
          { label: '排序', value: 'sortNo', type: 'number', comp: 'el-input', widthSize: 1 },
          {
            labelRender: () => {
              return (
                <div class="label_render">
                  <div>图片</div>
                  <el-tooltip >
                    <template slot="content">
                      <div>尺寸：114*118</div>
                    </template>
                    <i class="el-icon-warning-outline" style="cursor: pointer;margin-right:2px;" />
                  </el-tooltip>
                </div>
              )
            }, value: 'image', slotName: 'image', widthSize: 1,
          },
        ],
        rules: {
          tagName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
          // categoryId: [{ required: true, message: '请选择所属分类', trigger: 'change' }],
          sortNo: [{ required: true, message: '请输入排序值', trigger: 'blur' }],
          image: [{ validator: this.validateImg, message: '请选择图片', trigger: 'change' }],
        }
      },
      table: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        data: [],
        firstColumn: { type: 'selection' },
        operator: [
          {
            text: '编辑',
            fun: this.handleUpdate
          },
          {
            text: '删除',
            fun: this.handleDelete
          }
        ]
      },
      columns: [
        { prop: 'id', label: 'ID', minWidth: '100', sort: true},
        { prop: 'gameInfoName', label: '所属游戏', minWidth: '180'},
        { prop: 'categoryName', label: '所属分类', minWidth: '180' },
        {
          prop: 'image',
          label: '图片',
          minWidth: '100',
          slotName: 'image'
        },
        { prop: 'tagName', label: '标签名称', minWidth: '220', sort: true},
        { prop: 'sortNo', label: '排序', minWidth: '50'},
        { prop: 'createTime', label: '创建日期', minWidth: '220'}
      ]
    }
  },
  created() {
    const tagcategoryId = this.$route.params && this.$route.params.tagcategoryId;
    this.queryParams.categoryId = tagcategoryId;
  },
  methods: {
    validateImg(rule, value, callback) {
      if (this.formOpts.formData.image != null) {
        callback()
      }
      callback(new Error(rule.message))
    },
    changeFileList(file) {
      if (file.length === 0) {
        this.formOpts.formData.image = null
      } else {
        this.formOpts.formData.image = file[0].url
      }
    },
    customhandleAdd() {
      this.bannerProjectId=null
      this.messageProjectId=null
      this.isUpdate = false
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data().formOpts.formData
      )
      this.formOpts.formData.categoryId = this.queryParams.categoryId;
      this.open = true
      this.title = '新增'
    },
  }
}
</script>
