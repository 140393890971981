import { render, staticRenderFns } from "./sku.vue?vue&type=template&id=da0ccbbe"
import script from "./sku.vue?vue&type=script&lang=js"
export * from "./sku.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\git_owner\\ecgame\\ec-game-manage\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('da0ccbbe')) {
      api.createRecord('da0ccbbe', component.options)
    } else {
      api.reload('da0ccbbe', component.options)
    }
    module.hot.accept("./sku.vue?vue&type=template&id=da0ccbbe", function () {
      api.rerender('da0ccbbe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/game/goods/sku.vue"
export default component.exports