<template>
    <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
            <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            v-if="handleAdd"
            @click="handleAdd"
            >新增</el-button>
        </el-col>
        <el-col :span="1.5">
            <el-button
            type="danger"
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            v-if="handleDelete"
            @click="handleDelete"
            >删除</el-button>
        </el-col>
        <el-col :span="1.5">
            <el-button
            type="warning"
            icon="el-icon-upload"
            size="mini"
            v-if="handleImport"
            @click="handleImport"
            >导入</el-button>
        </el-col>
        <el-col :span="1.5">
            <el-button
            type="warning"
            icon="el-icon-download"
            size="mini"
            v-if="handleExport"
            @click="handleExport"
            >导出</el-button>
        </el-col>
    </el-row>
</template>

<script>
export default {
    props: ['url', 'handleAdd', 'handleDelete','downloadModel', 'handleImport', 'handleExport',  'single', 'multiple']
}
</script>

