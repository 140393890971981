var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "t_layout_page_item",
      class: { t_layout_page_item_bottm_margin: _vm.isNoBottomMargin },
      attrs: { id: "t_layout_page_item" },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }