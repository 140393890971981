var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-dialog" },
    [
      _c(
        "el-dialog",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "dialog-drag",
                  rawName: "v-dialog-drag",
                  value: { isShowDialogDrag: _vm.isShowDialogDrag },
                  expression: "{isShowDialogDrag}",
                },
              ],
              staticClass: "t-dialog-content",
            },
            "el-dialog",
            _vm.attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm._l(_vm.$slots, function (index, name) {
            return _c("template", { slot: name }, [_vm._t(name)], 2)
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }