<template>
  <div>
    <el-form :inline="true" class="search-form">
      <el-date-picker v-model="dateRange" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      <el-button type="primary" @click="search">搜索</el-button>
    </el-form>
    <!-- 前面的面板布局 -->
    <el-row>
      <el-col :span="8">
        <div class="panel-content">
          <div class="panel-top-left">系统总销售金额</div>
          <div class="panel-number">{{ formatMoney(statisticsData.totalSales) }}</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="panel-content">
          <div class="panel-top-left">系统总订单数</div>
          <div class="panel-number">{{ statisticsData.totalOrder }}</div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="panel-content">
          <div class="panel-top-left">系统总用户总数</div>
          <div class="panel-number">{{ statisticsData.totalUser }}</div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <div class="panel-content">
          <div class="panel-top-left">今日当前用户访问量</div>
          <div class="panel-number">{{ statisticsData.todayUserVisit }}</div>
          <div class="arrow-info">
            <i v-if="statisticsData.todayUserVisitRing !== 0" 
            :class="statisticsData.todayUserVisitRing > 0? 'el-icon-arrow-up' : 'el-icon-arrow-down'" :style="{ color: statisticsData.todayUserVisitRing >= 0?'red' : 'green' }"></i>
            <span>{{ statisticsData.todayUserVisitRing }}%</span>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="panel-content">
          <div class="panel-top-left">今日当前新增用户数量</div>
          <div class="panel-number">{{ statisticsData.todayNewUser }}</div>
          <div class="arrow-info">
            <i v-if="statisticsData.todayNewUserRing!== 0" 
            :class="statisticsData.todayNewUserRing > 0? 'el-icon-arrow-up' : 'el-icon-arrow-down'" :style="{ color: statisticsData.todayNewUserRing >= 0?'red' : 'green' }"></i>
            <span>{{ statisticsData.todayNewUserRing }}%</span>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="panel-content">
          <div class="panel-top-left">今日当前新增订单数</div>
          <div class="panel-number">{{ statisticsData.todayNewOrder }}</div>
          <div class="arrow-info">
            <i v-if="statisticsData.todayNewOrderRing!== 0" 
            :class="statisticsData.todayNewOrderRing > 0? 'el-icon-arrow-up' : 'el-icon-arrow-down'" :style="{ color: statisticsData.todayNewOrderRing >= 0?'red' : 'green' }"></i>
            <span>{{ statisticsData.todayNewOrderRing }}%</span>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">
        <div class="panel-content">
          <div class="panel-top-left">今日当前新增销售额</div>
          <div class="panel-number">{{ formatMoney(statisticsData.todayNewSales) }}</div>
          <div class="arrow-info">
            <i v-if="statisticsData.todayNewSalesRing!== 0" 
            :class="statisticsData.todayNewSalesRing > 0? 'el-icon-arrow-up' : 'el-icon-arrow-down'" :style="{ color: statisticsData.todayNewSalesRing >= 0?'red' : 'green' }"></i>
            <span>{{ statisticsData.todayNewSalesRing }}%</span>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="panel-content">
          <div class="panel-top-left">今日当前新增退款订单数</div>
          <div class="panel-number">{{ statisticsData.todayNewRefundOrder }}</div>
          <div class="arrow-info">
            <i v-if="statisticsData.todayNewRefundOrderRing!== 0" 
            :class="statisticsData.todayNewRefundOrderRing > 0? 'el-icon-arrow-up' : 'el-icon-arrow-down'" :style="{ color: statisticsData.todayNewRefundOrderRing >= 0?'red' : 'green' }"></i>
            <span>{{ statisticsData.todayNewRefundOrderRing }}%</span>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="panel-content">
          <div class="panel-top-left">今日当前新增退款金额</div>
          <div class="panel-number">{{ formatMoney(statisticsData.todayNewRefundAmount) }}</div>
          <div class="arrow-info">
            <i v-if="statisticsData.todayNewRefundAmountRing!== 0" 
            :class="statisticsData.todayNewRefundAmountRing > 0? 'el-icon-arrow-up' : 'el-icon-arrow-down'" :style="{ color: statisticsData.todayNewRefundAmountRing >= 0?'red' : 'green' }"></i>
            <span>{{ statisticsData.todayNewRefundAmountRing }}%</span>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 新的块：搜索表单和折线图区域 -->
    <!-- 搜索表单和折线图区域 -->
    <div class="search-chart-container">
      <el-tabs type="border-card" @tab-click="handleTabClick">
        <el-tab-pane label="销售额">
          <div ref="lineChartAmount" style="width: 100%; height: 300px;"></div>
        </el-tab-pane>
          <el-tab-pane label="订单数">
          <div ref="lineChartOrder" style="width: 100%; height: 300px;"></div>
        </el-tab-pane>
        <el-tab-pane label="用户数">
          <div ref="lineChartUser" style="width: 100%; height: 300px;"></div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import { formatMoney } from '@/utils/utils'

export default {
  name: 'Index',
  data() {
    const currentDate = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    return {
      dateRange: [sevenDaysAgo, currentDate],
      lineChartUser: null,
      lineChartOrder: null,
      lineChartAmount: null,
      activeTab: "销售额",
      url:{
        statistics:"/home/statistics",
        chart:"/home/chart",
      },
      statisticsData:{
        todayNewOrder: 0,
        todayNewOrderRing: 0,
        todayNewRefundAmount: 0,
        todayNewRefundAmountRing: 0,
        todayNewRefundOrder: 0,
        todayNewRefundOrderRing: 0,
        todayNewSales: 0,
        todayNewSalesRing: 0,
        todayNewUser: 0,
        todayNewUserRing: 0,
        todayUserVisit: 0,
        todayUserVisitRing: 0,
        totalSales: 0,
        totalUser: 0
      },
      chartData: {
        // 这里假设初始数据，实际使用中可通过接口获取或其他方式设置
        userData: {
          xdatas:null,
          ydatas:null,
        },
        orderData: {
          xdatas:null,
          ydatas:null,
        },
        salesData: {
          xdatas:null,
          ydatas:null,
        }
      }
    };
  },
  mounted() {
    this.getStatistics();
    this.getChart(2);
    this.resize();
  },
  methods: {
    formatMoney,
    getStatistics(){
      const date = this.getQueryDate();
      this.get(`${this.url.statistics}?startTime=${date.startDateString}&endTime=${date.endDateString}`).then(res => {
        this.statisticsData = res.data;
      }).catch(() => {
        this.msgError('搜索失败');
      });
    },
    getChart(type){
      if(this.dateRange == null){
        alert("请选择日期");
        return;
      }
      const date = this.getQueryDate();
      this.get(`${this.url.chart}?type=${type}&startTime=${date.startDateString}&endTime=${date.endDateString}`).then(res => {
        if(type == 0){
          this.chartData.userData.xdatas = res.data.xdatas;
          this.chartData.userData.ydatas = res.data.ydatas;
        }
        else if(type == 1){
          this.chartData.orderData.xdatas = res.data.xdatas;
          this.chartData.orderData.ydatas = res.data.ydatas;
        }
        else if(type == 2){
          this.chartData.salesData.xdatas = res.data.xdatas;
          this.chartData.salesData.ydatas = res.data.ydatas;
        }
        this.resize();
      }).catch(() => {
        this.msgError('搜索失败');
      });
    },
    getQueryDate(){
      if(this.dateRange == null){
        alert("请选择日期");
        return;
      }
      const startDate = this.dateRange[0];
      const endDate = this.dateRange[1];
      const startDateString = startDate.getFullYear() + '-' + (startDate.getMonth() + 1).toString().padStart(2, '0') + '-' + startDate.getDate().toString().padStart(2, '0');
      const endDateString = endDate.getFullYear() + '-' + (endDate.getMonth() + 1).toString().padStart(2, '0') + '-' + endDate.getDate().toString().padStart(2, '0');
      return {
          startDateString: startDateString,
          endDateString: endDateString
      };
    },
    search() {
      let type = 2;
      if (this.activeTab === '用户数') {
        type = 0;
      } else if (this.activeTab === '订单数') {
        type = 1;
      } else if (this.activeTab === '销售额') {
        type = 2;
      }
      this.getChart(type);
      this.getStatistics();
    },
    handleTabClick(tab) {
      this.activeTab = tab.label;
      this.search();
    },
    getOption(xAxisData, seriesData, isShowMoney){
      return {
          tooltip: {
            trigger: 'axis',
            formatter: function (params) {
                let tip = isShowMoney ? params[0].name + '<br>'+ formatMoney(params[0].value) : params[0].name + '<br>'+ params[0].value + "个";
                return tip;
            }
        },
        xAxis: {
          type: 'category',
          data: xAxisData
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: seriesData,
          type: 'line'
        }]
      };
    },
    initCharts() {
      // 用户数折线图
      this.lineChartUser = echarts.init(this.$refs.lineChartUser);
      const option1 = this.getOption(this.chartData.userData.xdatas, this.chartData.userData.ydatas, false);
      this.lineChartUser.setOption(option1);

      // 订单数折线图
      this.lineChartOrder = echarts.init(this.$refs.lineChartOrder);
      const option2 = this.getOption(this.chartData.orderData.xdatas, this.chartData.orderData.ydatas, false)
      this.lineChartOrder.setOption(option2);

      // 销售额折线图
      this.lineChartAmount = echarts.init(this.$refs.lineChartAmount);
      const option3 = this.getOption(this.chartData.salesData.xdatas, this.chartData.salesData.ydatas, true)
      this.lineChartAmount.setOption(option3);
    },
    resize(){
      this.$nextTick(()=>{
        this.initCharts();
        this.lineChartUser.resize();
        this.lineChartOrder.resize();
        this.lineChartAmount.resize();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-content {
  height: 100px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 16px;
}

.panel-top-left {
  position: absolute;
  top: 10px;
  left: 10px;
}

.panel-number {
  font-size: 24px;
}

.arrow-info {
  position: absolute;
  bottom: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}

.arrow-info i {
  font-size: 18px;
  margin-right: 5px;
}

.search-chart-container {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #e1e1e1;
}

.search-form {
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  .chart-wrapper {
    padding: 8px;
  }
}
</style>
