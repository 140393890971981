var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.comp,
    _vm._g(
      _vm._b(
        {
          ref:
            _vm.comp === "t-select-table"
              ? "tselecttableref-" + _vm.dataIndex
              : "",
          tag: "component",
          attrs: {
            placeholder: _vm.placeholder || _vm.getPlaceholder(_vm.opt),
            value: _vm.value,
          },
        },
        "component",
        typeof _vm.bind == "function"
          ? _vm.bind(_vm.form)
          : Object.assign({}, { clearable: true, filterable: true }, _vm.bind),
        false
      ),
      _vm.cEvent
    ),
    _vm._l(_vm.child, function (cOpt, i) {
      return _c(
        "OptComponent",
        _vm._b(
          { key: i, attrs: { value: cOpt.value } },
          "OptComponent",
          cOpt,
          false
        )
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }