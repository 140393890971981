var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "t_layout_page",
      attrs: { id: "t_layout_page" },
      on: {
        scroll: function (e) {
          return (_vm.scrollTop = e.target.scrollTop)
        },
      },
    },
    [
      _vm._t("default"),
      _vm.showGoTopButton
        ? _c("div", { staticClass: "back_to_top" }, [
            _vm.isShowGoTopButton
              ? _c("div", { on: { click: _vm.backToTop } }, [
                  _c("i", {
                    staticClass: "top_icon",
                    class: _vm.topIcon,
                    staticStyle: { color: "#5cb6ff" },
                  }),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }