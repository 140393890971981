<template>
  <div class="app-container">


    <Search :searchOpts="searchOpts" :listTypeInfo="listTypeInfo" @onSearch="onSearch" @onReset="onReset"/>


    <TableToolBar :url="url" :single="single" :multiple="multiple" :handleAdd="customhandleAdd"
                  :handleDelete="handleDelete"
    />

    <div class="t-table" style="width:100%;">
      <t-table :table="table"
               isShowPagination
               :columns="columns"
               :listTypeInfo="listTypeInfo"
               @selection-change="selectionChange"
               @page-change="pageChange"
      >
      </t-table>
    </div>


    <t-dialog :title="formOpts.title" :visible.sync="open" width="950px" :isShowDialogDrag="true"  append-to-body>
      <t-form
        :ref-obj.sync="formOpts.ref"
        :formOpts="formOpts"
        :listTypeInfo="listTypeInfo"
        :widthSize="2"
      >
      </t-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </t-dialog>

  </div>
</template>
<script>
import { commonMixin } from '@/mixins'

export default {
  mixins: [commonMixin],
  name: "Gamegoodssku",
  data() {
    return {
      title: '商品SKU管理',
      url: {
        list: '/productSkus/list/',
        getInfo: '/productSkus/',
        add: '/productSkus/',
        edit: '/productSkus/',
        del: '/productSkus/'
      },
      searchOpts: [
        { label: 'SKU名称', prop: 'title', element: 'el-input', likeType: 'contains' },
        { label: '创建时间', prop: 'createTime', element: 'el-date-picker' },
      ],
      listTypeInfo: {
        // categoryList: [],
      },
      sortOptions: {
        createTime: 'desc'
      },
      queryParams: {
        goodsId: null,
      },
      formOpts: {
        title: 'SKU信息',
        ref: null,
        labelWidth: '120px',
        formData: {
          id: null,
          title: null,
          price: null,
          discountedPrice: null,
          goodsId: null,
          inventory: 999999,
          sortNo: null,
        },
        fieldList: [
          { label: '名称', value: 'title', comp: 'el-input', widthSize: 1 },
          { label: '通常价格', value: 'price', type: 'number', comp: 'el-input', widthSize: 1},
          { label: '折扣价', value: 'discountedPrice', type: 'number', comp: 'el-input', widthSize: 1},
          { label: '库存', value: 'inventory', type: 'number', comp: 'el-input', widthSize: 1},
          { label: '排序', value: 'sortNo', type: 'number', comp: 'el-input', widthSize: 1 },
        ],
        rules: {
          title: [{ required: true, message: '请输入名称', trigger: 'blur' }],
          price: [{ required: true, message: '请输入通常价格', trigger: 'blur' }],
          discountedPrice: [{ required: true, message: '请输入折扣价格', trigger: 'blur' }],
          inventory: [{ required: true, message: '请输入库存', trigger: 'blur' }],
          sortNo: [{ required: true, message: '请输入排序值', trigger: 'blur' }],
        }
      },
      table: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        data: [],
        firstColumn: { type: 'selection' },
        operator: [
          {
            text: '编辑',
            fun: this.handleUpdate
          },
          {
            text: '删除',
            fun: this.handleDelete
          }
        ]
      },
      columns: [
        { prop: 'id', label: 'ID', minWidth: '100'},
        { prop: 'title', label: '名称', minWidth: '180'},
        { prop: 'price', label: '价格', minWidth: '80' },
        { prop: 'discountedPrice', label: '折扣价', minWidth: '80'},
        { prop: 'inventory', label: '库存', minWidth: '80'},
        { prop: 'sortNo', label: '排序', minWidth: '100'},
        { prop: 'createTime', label: '创建日期', minWidth: '220'}
      ]
    }
  },
  created() {
    const goodsId = this.$route.params && this.$route.params.goodsId;
    this.queryParams.goodsId = goodsId;
  },
  methods: {
    /** 新增按钮操作 */
    customhandleAdd() {
      this.bannerProjectId=null
      this.messageProjectId=null
      this.isUpdate = false
      Object.assign(
        this.$data.formOpts.formData,
        this.$options.data().formOpts.formData
      )
      this.formOpts.formData.goodsId = this.queryParams.goodsId;
      this.open = true
      this.title = '新增'
    },
  }
}
</script>
