<template>
  <div class="search-form-box">
    <el-form
      :model="formData"
      ref="formRef"
      :inline="true">
      <el-form-item
        v-for="(item, index) in newSearchOpts"
        :key="index"
        :prop="item.prop"
        :label="item.label ? (item.label + '：') : ''"
        :rules="item.rules">
        <formItem v-show="!item.hidden" v-model="formData[item.prop]" :itemOptions="item" :listTypeInfo="listTypeInfo"/>
      </el-form-item>
      <!-- 按钮 -->
      <el-form-item>
        <div class="btn-box">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-search"
            @click="onSearch"
          >搜索
          </el-button>
          <el-button
            size="mini"
            type="default"
            icon="el-icon-refresh"
            @click="onReset"
          >重置
          </el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import formItem from './formItem'

  export default {
    props: {
      /**
       * 表单配置
       * 示例：
       * [{
       *   label: '用户名', // label文字
       *   prop: 'username', // 字段名
       *   element: 'el-input', // 指定elementui组件
       *   initValue: '阿黄', // 字段初始值
       *   placeholder: '请输入用户名', // elementui组件属性
       *   rules: [{ required: true, message: '必填项', trigger: 'blur' }], // elementui组件属性
       *   events: { // elementui组件方法
       *     input (val) {
       *       console.log(val)
       *     },
       *     ...... // 可添加任意elementui组件支持的方法
       *   }
       *   ...... // 可添加任意elementui组件支持的属性
       * }]
       */
      searchOpts: {
        type: Array,
        required: true,
        default() {
          return []
        }
      },
      listTypeInfo: {
        type: Object,
        default() {
          return {}
        }
      },
      // 提交按钮项，多个用逗号分隔（search, export, reset）
      btnItems: {
        type: String,
        default() {
          return 'search'
        }
      }
    },

    data() {
      return {
        formData: {},
        newSearchOpts: []
      }
    },

    created() {
      this.newSearchOpts = JSON.parse(JSON.stringify(this.searchOpts));
      this.addInitValue()
    },

    methods: {
      // 校验
      onValidate(callback) {
        this.$refs.formRef.validate(valid => {
          if (valid) {
            callback()
          }
        })
      },
      // 搜索
      onSearch() {
        this.onValidate(() => {
          let queryFrom = JSON.parse(JSON.stringify(this.formData));
          queryFrom.pageIndex = 1;
          queryFrom.pageSize = 10;

          this.searchOpts.forEach((item) => {
            if (item.likeType && queryFrom[item.prop]) {
              if (item.likeType === "start") {
                queryFrom[item.prop] = "*" + queryFrom[item.prop];
              } else if (item.like === "end") {
                queryFrom[item.prop] = queryFrom[item.prop] + "*";
              } else {
                queryFrom[item.prop] = "*" + queryFrom[item.prop] + "*";
              }
            }
          })
          this.$emit('onSearch', queryFrom)
        })
      },
      // 导出
      onExport() {
        this.onValidate(() => {
          let queryFrom = JSON.parse(JSON.stringify(this.formData));
          queryFrom.pageIndex = 1;
          queryFrom.pageSize = 10;
          this.searchOpts.forEach((item) => {
            if (item.like && queryFrom[item.prop]) {
              if (item.like == this.$likeType.LEFT) {
                queryFrom[item.prop] = "*" + queryFrom[item.prop];
              } else if (item.like == this.$likeType.RIGHT) {
                queryFrom[item.prop] = queryFrom[item.prop] + "*";
              } else {
                queryFrom[item.prop] = "*" + queryFrom[item.prop] + "*";
              }
            }
          })
          this.$emit('onExport', queryFrom)
        })
      },
      onReset() {
        let object = this.$refs.formRef.model
        for (const key in object) {
          object[key] = undefined;
        }
        object.pageIndex = 1;
        object.pageSize = 10;
        this.newSearchOpts = JSON.parse(JSON.stringify(this.searchOpts));
        this.$emit('onReset', this.formData)
      },
      // 添加初始值
      addInitValue() {
        const obj = {}
        this.searchOpts.forEach(v => {
          if (v.initValue !== undefined) {
            obj[v.prop] = v.initValue
          }
        })
        this.formData = obj
      }
    },

    components: {formItem}
  }
</script>
