var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "input-search-select" },
    [
      _c("el-input", {
        directives: [
          {
            name: "input-debounce",
            rawName: "v-input-debounce",
            value: [_vm.inputChange, 300],
            expression: "[inputChange, 300]",
          },
        ],
        attrs: { placeholder: _vm.placeholder },
        on: { focus: _vm.inputFocus, blur: _vm.inputBlur },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "value",
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isShow,
              expression: "isShow",
            },
          ],
          staticClass: "select-list",
          attrs: { id: _vm.id },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
            },
            _vm._l(_vm.data.data, function (item) {
              return _c(
                "div",
                {
                  key: item.id,
                  staticClass: "li",
                  attrs: { title: item.name },
                  on: {
                    click: function ($event) {
                      return _vm.clickItem(item)
                    },
                  },
                },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            0
          ),
          _vm.data.data &&
          _vm.data.data.length > 0 &&
          _vm.data.data.length < _vm.data.total
            ? _c(
                "el-button",
                {
                  staticClass: "load-more",
                  attrs: { loading: _vm.btnLoading },
                  on: { click: _vm.getMore },
                },
                [_vm._v("加载更多(" + _vm._s(_vm.data.total) + ")")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }