<!-- tinymc富文本 -->
<template>
  <div>
    <editor
      v-model="myValue"
      :init="init"
      :disabled="disabled"
      @onClick="onClick"
    >
    </editor>
  </div>
</template>

<script>
import {upload} from "../../api/system/upload";
import tinymce from "./tinymce";
import Editor from "@tinymce/tinymce-vue";
import "./tinymce/skins/ui/oxide/skin.css";
import "@/assets/tinymce/zh_CN.js"; // 本地汉化
import "./tinymce/themes/silver/theme";
import "./tinymce/plugins/image";
import "./tinymce/plugins/paste";
import "./tinymce/plugins/preview";
import './tinymce/plugins/media'
import './tinymce/plugins/table'
import "./tinymce/plugins/lists";
import "./tinymce/plugins/contextmenu";
import "./tinymce/plugins/wordcount";
import "./tinymce/plugins/colorpicker";
import "./tinymce/plugins/textcolor";
import "./tinymce/icons/default";
export default {
  name: "tinymce",
  components: {
    Editor,
  },
  props: {
    //传入一个value，使组件支持v-model绑定
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: [String, Array],
      default:
      // ' anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools insertdatetime link lists   noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'
        "lists image media table  wordcount paste preview",
    },
    toolbar: {
      type: [String, Array],
      default:
      // 'undo redo | searchreplace | bold  italic | underline | strikethrough | alignleft  aligncenter alignright | outdent indent  blockquote  removeformat subscript superscript code codesample hr bullist numlist link image charmap preview anchor pagebreak insertdatetime  table  forecolor backcolor'
        "undo redo preview  | fontfamily formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | lists image media table | removeformat hr ",
    },
  },
  data() {
    return {

      //初始化配置
      init: {
        // images_upload_url: process.env.VUE_APP_BASE_API + "/oss/upload", // 上传的图片服务器地址
        language_url: "@/asset/tinymce/langs/zh_CN.js", // 这里需要单独处理
        language: "zh_CN",
        height: 800,
        width: 800,
        plugins: this.plugins,
        toolbar: this.toolbar,
        branding: false,
        menubar: true,
        paste_data_images: true,
        content_style: "p {margin: 10px 0;} img {max-width:100%;}",
        images_upload_handler: (blobInfo, success, failure) => {
          this.handleUpload(blobInfo, success, failure)
        },
        resize: true,
      },
      myValue: this.value,
    };
  },
  mounted() {
    tinymce.init({});
  },
  methods: {
    handleUpload(blobInfo, success, failure) {
      let file=blobInfo.blob();
      const formdata = new FormData();
      formdata.append('file', file);
      upload(formdata).then(res => {
        success(res.data)
      }).catch(err => {
        failure(err)
      })
    },
    //添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
    //需要什么事件可以自己增加
    onClick(e) {
      this.$emit("onClick", e, tinymce);
    },
    clear() {
      this.myValue = "";
    },
  },
  watch: {
    value(newValue) {
      debugger
      this.myValue = newValue == null? "" : newValue;
    },
    myValue(newValue) {
      debugger
      this.$emit("input", newValue == null? "" : newValue);
    },
  },
};
</script>
<style scoped></style>
