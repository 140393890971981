import Vue from 'vue'

import Cookies from 'js-cookie'

import Element from 'element-ui'
import './assets/styles/element-variables.scss'

import '@/assets/styles/index.scss' // global css
import '@/assets/styles/ruoyi.scss' // ruoyi css
import App from './App'
import store from './store'
import router from './router'
import directive from './directive' // directive
import plugins from './plugins' // plugins
import { download } from '@/utils/request'

import './assets/icons' // icon
import './permission' // permission control
import { getDicts } from "@/api/system/dict/data";
import { getConfigKey } from "@/api/system/config";
import { parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, handleTree, toStr,getDict } from "@/utils/ruoyi";
// 分页组件
import Pagination from "@/components/Pagination";
// 自定义表格工具组件
import RightToolbar from "@/components/RightToolbar"
// 文件上传组件
import FileUpload from "@/components/FileUpload"
// 图片上传组件
import ImageUpload from "@/components/ImageUpload"
// 图片预览组件
import ImagePreview from "@/components/ImagePreview"
// 字典标签组件
import DictTag from '@/components/DictTag'
import TableToolBar from '@/components/TableToolBar'
import Search from '@/components/Search'
// 头部标签组件
import VueMeta from 'vue-meta'
// 字典数据组件
import DictData from '@/components/DictData'
import dayjs from 'dayjs'
import md5 from 'js-md5';
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import { get,put,post,del } from '@/api/api'
import Tinymce from '@/components/Tinymce';
import {TTable,TForm} from "@/components/t-ui/packages"
import TUploadFile from "@/components/t-ui/packages/upload-file"
import Tui from "@/components/t-ui"
import GoEasy from 'goeasy';



// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.getConfigKey = getConfigKey
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.toStr = toStr
Vue.prototype.getDict = getDict
Vue.prototype.get = get; //全局挂载
Vue.prototype.post = post; //全局挂载
Vue.prototype.del = del; //全局挂载
Vue.prototype.put = put; //全局挂载
Vue.prototype.$dayjs = dayjs
Vue.prototype.msgSuccess = function (msg) {
  this.$message({ showClose: true, message: msg, type: "success" });
}
Vue.prototype.msgError = function (msg) {
  this.$message({ showClose: true, message: msg, type: "error" });
}

// 全局组件挂载
Vue.component('DictTag', DictTag)
Vue.component('Pagination', Pagination)
Vue.component('RightToolbar', RightToolbar)
Vue.component('FileUpload', FileUpload)
Vue.component('ImageUpload', ImageUpload)
Vue.component('ImagePreview', ImagePreview)
Vue.component('video-player', VideoPlayer)
Vue.component('TableToolBar', TableToolBar)
Vue.component('Search', Search)
Vue.component('Tinymce', Tinymce)


Vue.use(directive)
Vue.use(plugins)
Vue.use(VueMeta)

Vue.use(Tui)
Vue.use(TTable)
Vue.use(TForm)
Vue.use(TUploadFile)

Vue.use(VideoPlayer)
DictData.install()

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

Vue.use(Element, {
  size: Cookies.get('size') || 'medium' // set element-ui default size
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})

// const goEasy = GoEasy.getInstance({
// 	host: 'hangzhou.goeasy.io',	//应用所在的区域地址: 【hangzhou.goeasy.io |singapore.goeasy.io】
// 	appkey: 'BC-7d6dbfec48d54b049a33a4a9e542dcc9', // common key,
// 	modules: ['im']
// });

const goEasy = GoEasy.getInstance({
	host: 'singapore.goeasy.io',	//应用所在的区域地址: 【hangzhou.goeasy.io |singapore.goeasy.io】
	appkey: 'BC-f1a2874605d84d87b69eb8db511b79b8', // common key,
	modules: ['im']
});

Vue.prototype.GoEasy = GoEasy;
Vue.prototype.goEasy = goEasy;