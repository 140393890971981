var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-input" },
    [
      _vm.isTip
        ? _c(
            "el-tooltip",
            {
              attrs: {
                disabled: !_vm.value,
                placement: "bottom-start",
                effect: "light",
                "popper-class": "tooltip-custom text-danger text-bold",
              },
            },
            [
              _c("div", { attrs: { slot: "content" }, slot: "content" }, [
                _vm._v(" " + _vm._s(_vm._f("currencyFilter")(_vm.value)) + " "),
                _c("br"),
                _vm._v(" " + _vm._s(_vm._f("digitUppercase")(_vm.value)) + " "),
              ]),
              _c(
                "el-input",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        type: "text",
                        placeholder: _vm.placeholder,
                        clearable: "",
                        value: _vm.currentVal,
                      },
                      on: {
                        blur: _vm.projectAmounts,
                        clear: _vm.clearValue,
                        input: function (value) {
                          return _vm.$emit("input", value)
                        },
                      },
                    },
                    "el-input",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                ),
                [
                  _vm.isShow
                    ? _c("template", { slot: "append" }, [
                        _vm._v(_vm._s(_vm.appendTitle)),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _c(
            "el-input",
            _vm._g(
              _vm._b(
                {
                  attrs: {
                    type: "text",
                    placeholder: _vm.placeholder,
                    clearable: "",
                    value: _vm.currentVal,
                  },
                  on: {
                    blur: _vm.projectAmounts,
                    clear: _vm.clearValue,
                    input: function (value) {
                      return _vm.$emit("input", value)
                    },
                  },
                },
                "el-input",
                _vm.$attrs,
                false
              ),
              _vm.$listeners
            ),
            [
              _vm.isShow
                ? _c("template", { slot: "append" }, [
                    _vm._v(_vm._s(_vm.appendTitle)),
                  ])
                : _vm._e(),
            ],
            2
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }