var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-protocolSrc" },
    [
      _c(
        "el-dialog",
        _vm._g(
          _vm._b(
            {
              attrs: {
                title: _vm.protocolTitle,
                visible: _vm.protocolVisible,
                width: _vm.protocolWidth,
                "before-close": _vm.handleClose,
                top: "5vh",
                center: "",
              },
            },
            "el-dialog",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _c(
            "div",
            { staticClass: "pdf", style: { height: _vm.protocolDialogHeight } },
            [
              _c("iframe", {
                staticStyle: { width: "100%", height: "100%", padding: "10px" },
                attrs: { src: _vm.protocolSrc, frameborder: "0" },
              }),
            ]
          ),
          _vm.protocolCheck
            ? _c(
                "div",
                { staticClass: "dialog-check" },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.checked,
                        callback: function ($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked",
                      },
                    },
                    [_vm._v("请你仔细阅读以上协议,并勾选")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.protocolCheck
                ? _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.open } },
                    [_vm._v("立即开通")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.close } },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }