import axios from 'axios'
import { Notification, MessageBox, Message, Loading } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import errorCode from '@/utils/errorCode'
import { tansParams, blobValidate } from "@/utils/ruoyi";
import cache from '@/plugins/cache'
import { saveAs } from 'file-saver'

let downloadLoadingInstance;
// 是否显示重新登录
export let isRelogin = { show: false };

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 60000
})

// request拦截器
service.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
    const limitSize = 5 * 1024 * 1024; // 限制存放数据5M
    if (requestSize >= limitSize) {
      console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。')
      return config;
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                  // 请求地址
      const s_data = sessionObj.data;                // 请求数据
      const s_time = sessionObj.time;                // 请求时间
      const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交';
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  return config
}, error => {
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const status = res.data.status || 200;
    // 获取错误信息
    const msg = errorCode[status] || res.data.message || errorCode['default']
    // 二进制数据则直接返回
    if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
      return res.data
    }
    if (status === 401) {
      if (!isRelogin.show) {
        isRelogin.show = true;
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
          isRelogin.show = false;
          store.dispatch('LogOut').then(() => {
            location.href = '/index';
          })
      }).catch(() => {
        isRelogin.show = false;
      });
    }
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。')
    } else if (status === 500) {
      Message({ message: msg, type: 'error' })
      return Promise.reject(new Error(msg))
    } else if (status === 601) {
      Message({ message: msg, type: 'warning' })
      return Promise.reject('error')
    } else if (status !== 200) {
      Notification.error({ title: msg })
      return Promise.reject('error')
    } else {
      return res.data
    }
    // 如果需要重试，可以在这里添加逻辑
    if (status === 408 || status === 504) { // 超时错误
      // 尝试重试
      return retryRequest(res.config);
    }
    return res.data;
  },
  error => {
    let { message } = error;
    // 如果需要重试，可以在这里添加逻辑
    if (error.response && (error.response.status === 408 || error.response.status === 504)) {
      // 尝试重试
      return retryRequest(error.config);
    }

    console.error('Error details:', error); // 打印错误详情
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message({ message: message, type: 'error', duration: 5 * 1000 })
    return Promise.reject(error)
  }
)

// 重试函数
function retryRequest(config, retries = 0) {
  const maxRetries = 3; // 最大重试次数
  const delay = 2000; // 重试间隔时间（毫秒）

  return new Promise((resolve, reject) => {
    if (retries < maxRetries) {
      retries++;
      setTimeout(() => {
        service(config)
          .then(resolve)
          .catch(error => {
            if (error.response && (error.response.status === 408 || error.response.status === 504)) {
              resolve(retryRequest(config, retries));
            } else {
              reject(error);
            }
          });
      }, delay);
    } else {
      reject(error);
    }
  });
}
// 假设以下这些外部依赖的函数和变量都已经正确定义，比如 `service` 是用于发起请求的函数，`blobValidate` 用于验证是否为 `Blob` 类型，`saveAs` 用于保存文件，`Message` 用于显示消息，`errorCode` 是错误代码映射对象等
export function download(url, params, filename, config) {
  downloadLoadingInstance = Loading.service({
      text: "正在下载数据，请稍候",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
  });
  // 构建查询字符串（这里假设你的参数是简单的对象形式，可根据实际情况调整更复杂的参数处理逻辑）
  const queryString = Object.keys(params).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`).join('&');
  const fullUrl = `${url}?${queryString}`;
  filename = filename ? filename : `${new Date().getTime().toString()}.xlsx`
  return service.get(fullUrl, {
      headers: {
          // 设置请求头的 Content-Type，这里用于常规的表单形式的GET请求参数传递
          'Content-Type': 'application/x-www-form-urlencoded'
      },
      responseType: 'blob',
    ...config
  }).then((response) => {
      const isBlob = blobValidate(response);
      if (isBlob) {
          saveAs(response, filename)
      } else {
          const resText = response.data.text();
          const rspObj = JSON.parse(resText);
          const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default'];
          Message.error(errMsg);
      }
        downloadLoadingInstance.close();
      
  }).catch((r) => {
      console.error(r);
      Message.error('下载文件出现错误，请联系管理员！');
      downloadLoadingInstance.close();
  });
}

export default service
