var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { height: _vm.height + "px", zIndex: _vm.zIndex } },
    [
      _vm.position === "top"
        ? _c(
            "div",
            {
              class: _vm.className,
              style: {
                top: _vm.isSticky ? _vm.stickyTop + "px" : "",
                zIndex: _vm.zIndex,
                position: _vm.positions,
                width: _vm.width,
                height: _vm.height + "px",
              },
            },
            [_vm._t("default", [_c("div", [_vm._v("sticky")])])],
            2
          )
        : _vm._e(),
      _vm.position === "bottom"
        ? _c(
            "div",
            {
              class: _vm.className,
              style: {
                bottom: _vm.isSticky ? _vm.stickyTop + "px" : "",
                zIndex: _vm.zIndex,
                position: _vm.positions,
                width: _vm.width,
                height: _vm.height + "px",
              },
            },
            [_vm._t("default", [_c("div", [_vm._v("sticky")])])],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }