var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { attrs: { trigger: "click", placement: "bottom" } },
    [
      _c("el-button", _vm._b({}, "el-button", _vm.columnBind, false), [
        _vm._v(_vm._s(_vm.columnBind.btnTxt || "列设置")),
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown", divided: "" }, slot: "dropdown" },
        [
          _c("span", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.columnBind.title || "列设置")),
          ]),
          _c(
            "draggable",
            {
              staticClass: "t_table_column_setting_dropdown",
              model: {
                value: _vm.columnSet,
                callback: function ($$v) {
                  _vm.columnSet = $$v
                },
                expression: "columnSet",
              },
            },
            _vm._l(_vm.columnSet, function (col, index) {
              return _c(
                "el-checkbox",
                {
                  key: col.prop,
                  attrs: { checked: !col.hidden },
                  on: {
                    change: function (checked) {
                      return _vm.checkChanged(checked, index)
                    },
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.stopPropagation()
                    },
                  },
                },
                [_vm._v(_vm._s(col.label))]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }