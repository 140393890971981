var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.show ? _vm.comp : "",
    _vm._g(
      _vm._b(
        { tag: "component" },
        "component",
        typeof _vm.bind === "function" ? _vm.bind(_vm.scope) : _vm.bind,
        false
      ),
      _vm.event(_vm.scope)
    ),
    [
      _vm._v(" " + _vm._s(_vm.text) + " "),
      _vm._l(_vm.childOpt, function (cOpt, i) {
        return _c(
          "OptComponent",
          _vm._b(
            {
              key: i,
              attrs: { slot: cOpt.slot, scope: _vm.scope },
              slot: cOpt.slot,
            },
            "OptComponent",
            cOpt,
            false
          )
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }