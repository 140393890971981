var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      !_vm.topNav
        ? _c("breadcrumb", {
            staticClass: "breadcrumb-container",
            attrs: { id: "breadcrumb-container" },
          })
        : _vm._e(),
      _vm.topNav
        ? _c("top-nav", {
            staticClass: "topmenu-container",
            attrs: { id: "topmenu-container" },
          })
        : _vm._e(),
      _c(
        "el-row",
        { staticClass: "right-menu" },
        [
          _c("el-col", { attrs: { span: 3 } }, [
            _c(
              "div",
              { on: { click: _vm.trunOrderPage } },
              [
                _c(
                  "el-dropdown",
                  { attrs: { trigger: "hover" } },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "el-icon-bell el-icon-bell-large",
                        class: { "has-unread": _vm.unreadCount > 0 },
                      },
                      [
                        _vm.unreadCount > 0
                          ? _c("span", { staticClass: "unread-count" }, [
                              _vm._v(_vm._s(_vm.unreadCount)),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "el-dropdown-menu",
                      {
                        staticClass: "custom-dropdown-menu",
                        attrs: { slot: "dropdown" },
                        slot: "dropdown",
                      },
                      _vm._l(_vm.reminderList, function (reminder, index) {
                        return _c(
                          "el-dropdown-item",
                          {
                            key: index,
                            staticClass: "custom-dropdown-menu-item",
                          },
                          [_vm._v(" " + _vm._s(reminder.messageContent) + " ")]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "el-col",
            { staticStyle: { display: "none" }, attrs: { span: 8 } },
            [
              _c("div", [
                _c(
                  "span",
                  {
                    staticStyle: {
                      "background-color": "#F5F7FA",
                      "font-size": "20px",
                      width: "30px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.hours) + " ")]
                ),
                _c("span", [_vm._v(": ")]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "background-color": "#F5F7FA",
                      "font-size": "20px",
                      width: "30px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.minutes) + " ")]
                ),
                _c("span", [_vm._v(": ")]),
                _c(
                  "span",
                  {
                    staticStyle: {
                      "background-color": "#F5F7FA",
                      "font-size": "20px",
                      width: "30px",
                    },
                  },
                  [_vm._v(_vm._s(_vm.seconds) + " ")]
                ),
              ]),
            ]
          ),
          _c("el-col", { attrs: { span: 8 } }, [
            _c(
              "span",
              { staticStyle: { "font-size": "15px", "margin-left": "5px" } },
              [_vm._v(_vm._s(_vm.info.nickName))]
            ),
          ]),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c(
                "el-dropdown",
                {
                  staticClass: "avatar-container right-menu-item hover-effect",
                  attrs: { trigger: "click" },
                },
                [
                  _c("div", { staticClass: "avatar-wrapper" }, [
                    _c("img", {
                      staticClass: "user-avatar",
                      attrs: { src: _vm.avatar },
                    }),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ]),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: "/user/profile" } },
                        [_c("el-dropdown-item", [_vm._v("个人中心")])],
                        1
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          nativeOn: {
                            click: function ($event) {
                              _vm.setting = true
                            },
                          },
                        },
                        [_c("span", [_vm._v("布局设置")])]
                      ),
                      _c(
                        "el-dropdown-item",
                        {
                          attrs: { divided: "" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout($event)
                            },
                          },
                        },
                        [_c("span", [_vm._v("退出登录")])]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }