var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t_detail" },
    [
      _c(
        "el-descriptions",
        _vm._g(
          _vm._b(
            { attrs: { column: _vm.descColumn } },
            "el-descriptions",
            Object.assign({}, { size: "middle" }, _vm.$attrs),
            false
          ),
          _vm.$listeners
        ),
        _vm._l(_vm.descData, function (item, key) {
          return _c(
            "el-descriptions-item",
            _vm._b(
              { key: key, attrs: { label: item.label, span: item.span || 1 } },
              "el-descriptions-item",
              Object.assign({}, item.bind, _vm.$attrs),
              false
            ),
            [
              item.slotName
                ? [_vm._t(item.slotName)]
                : _c(
                    "div",
                    [
                      item.tooltip
                        ? _c(
                            "el-tooltip",
                            _vm._b(
                              {
                                scopedSlots: _vm._u(
                                  [
                                    item.tooltip
                                      ? {
                                          key: "content",
                                          fn: function () {
                                            return [
                                              typeof item.tooltip === "string"
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.tooltip)
                                                    ),
                                                  ])
                                                : typeof item.tooltip ===
                                                  "function"
                                                ? [
                                                    _c("render-tooltip", {
                                                      attrs: {
                                                        createElementFunc:
                                                          item.tooltip,
                                                      },
                                                    }),
                                                  ]
                                                : _vm._e(),
                                            ]
                                          },
                                          proxy: true,
                                        }
                                      : null,
                                  ],
                                  null,
                                  true
                                ),
                              },
                              "el-tooltip",
                              _vm.$attrs,
                              false
                            ),
                            [
                              _c("span", [
                                item.filters && item.filters.list
                                  ? _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("constantEscape")(
                                            _vm.dataList[item.fieldName],
                                            _vm.listTypeInfo[item.filters.list],
                                            item.filters.key || "dictValue",
                                            item.filters.label || "dictLabel"
                                          )
                                        )
                                      ),
                                    ])
                                  : _c("span", [_vm._v(_vm._s(item.value))]),
                                _c("i", {
                                  class:
                                    item.iconClass || "el-icon-warning-outline",
                                  staticStyle: { cursor: "pointer" },
                                }),
                              ]),
                            ]
                          )
                        : _c("span", [
                            item.filters && item.filters.list
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("constantEscape")(
                                        _vm.dataList[item.fieldName],
                                        _vm.listTypeInfo[item.filters.list],
                                        item.filters.key || "dictValue",
                                        item.filters.label || "dictLabel"
                                      )
                                    )
                                  ),
                                ])
                              : _c("span", [_vm._v(_vm._s(item.value))]),
                          ]),
                    ],
                    1
                  ),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }