var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t_detail" },
    [
      _c(
        "el-collapse",
        {
          model: {
            value: _vm.defaultActiveKey,
            callback: function ($$v) {
              _vm.defaultActiveKey = $$v
            },
            expression: "defaultActiveKey",
          },
        },
        _vm._l(_vm.descData, function (val, index) {
          return _c(
            "el-collapse-item",
            {
              key: index,
              class: { noTitle: !val.title, disabledStyle: val.disabled },
              attrs: {
                title: val.title,
                name: val.name,
                disabled: val.disabled,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "title",
                    fn: function () {
                      return [
                        _vm._v(" " + _vm._s(val.title) + " "),
                        val.btn
                          ? _c(
                              "div",
                              { staticClass: "t_btn" },
                              [_vm._t(val.btn)],
                              2
                            )
                          : _vm._e(),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                true
              ),
            },
            [
              val.slotName
                ? [_vm._t(val.slotName)]
                : _c(
                    "el-descriptions",
                    _vm._b(
                      {
                        class: { fontWight: _vm.fontBold },
                        attrs: { size: "middle", column: _vm.descColumn },
                      },
                      "el-descriptions",
                      _vm.$attrs,
                      false
                    ),
                    _vm._l(val.data, function (item, key) {
                      return _c(
                        "el-descriptions-item",
                        {
                          key: key,
                          attrs: { label: item.label, span: item.span || 1 },
                        },
                        [
                          item.slotName
                            ? [_vm._t(item.slotName)]
                            : _c(
                                "div",
                                [
                                  item.tooltip
                                    ? _c(
                                        "el-tooltip",
                                        _vm._b(
                                          {
                                            scopedSlots: _vm._u(
                                              [
                                                item.tooltip
                                                  ? {
                                                      key: "content",
                                                      fn: function () {
                                                        return [
                                                          typeof item.tooltip ===
                                                          "string"
                                                            ? _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.tooltip
                                                                  )
                                                                ),
                                                              ])
                                                            : typeof item.tooltip ===
                                                              "function"
                                                            ? [
                                                                _c(
                                                                  "render-tooltip",
                                                                  {
                                                                    attrs: {
                                                                      createElementFunc:
                                                                        item.tooltip,
                                                                    },
                                                                  }
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    }
                                                  : null,
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          "el-tooltip",
                                          _vm.$attrs,
                                          false
                                        ),
                                        [
                                          _c("span", [
                                            item.filters && item.filters.list
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("constantEscape")(
                                                        _vm.dataList[
                                                          item.fieldName
                                                        ],
                                                        _vm.listTypeInfo[
                                                          item.filters.list
                                                        ],
                                                        item.filters.key ||
                                                          "dictValue",
                                                        item.filters.label ||
                                                          "dictLabel"
                                                      )
                                                    )
                                                  ),
                                                ])
                                              : _c("span", [
                                                  _vm._v(_vm._s(item.value)),
                                                ]),
                                            _c("i", {
                                              class:
                                                item.iconClass ||
                                                "el-icon-warning-outline",
                                              staticStyle: {
                                                cursor: "pointer",
                                              },
                                            }),
                                          ]),
                                        ]
                                      )
                                    : _c("span", [
                                        item.filters && item.filters.list
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("constantEscape")(
                                                    _vm.dataList[
                                                      item.fieldName
                                                    ],
                                                    _vm.listTypeInfo[
                                                      item.filters.list
                                                    ],
                                                    item.filters.key ||
                                                      "dictValue",
                                                    item.filters.label ||
                                                      "dictLabel"
                                                  )
                                                )
                                              ),
                                            ])
                                          : _c("span", [
                                              _vm._v(_vm._s(item.value)),
                                            ]),
                                      ]),
                                ],
                                1
                              ),
                        ],
                        2
                      )
                    }),
                    1
                  ),
            ],
            2
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }