var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "timer-btn" }, [
    _c(
      "button",
      {
        staticClass: "sendSmsBtn",
        class: _vm.disabled ? "dissendSmsBtn" : "",
        attrs: { type: "button", disabled: _vm.disabled || _vm.time > 0 },
        on: { click: _vm.run },
      },
      [_vm._v(_vm._s(_vm.text))]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }