import request from '@/utils/request'
import { parseStrEmpty } from "@/utils/ruoyi";

//用户头像上传
export function upload(data) {
  return request({
    url: '/oss/upload',
    method: 'post',
    data: data
  })
}
