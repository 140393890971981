/**
 * 创建唯一的字符串
 * @return {string} ojgdvbvaua40
 */
// export function createUniqueString () {
//   const timestamp = +new Date() + ''
//   const randomNum = parseInt((1 + Math.random()) * 65536) + ''
//   return (+(randomNum + timestamp)).toString(32)
// }
  
  // elementui日期时间范围 快捷选项
export const pickerOptionsRange = {
  shortcuts: [
    {
      text: '今天',
      onClick (picker) {
        const end = new Date()
        const start = new Date(new Date().toDateString())
        start.setTime(start.getTime())
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近一周',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(end.getTime() - 3600 * 1000 * 24 * 7)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近一个月',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
        picker.$emit('pick', [start, end])
      }
    }, {
      text: '最近三个月',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
        picker.$emit('pick', [start, end])
      }
    }
  ]
}
  
  // elementui月份范围 快捷选项
export const pickerOptionsRangeMonth = {
  shortcuts: [
    {
      text: '今年至今',
      onClick (picker) {
        const end = new Date()
        const start = new Date(new Date().getFullYear(), 0)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '最近半年',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setMonth(start.getMonth() - 6)
        picker.$emit('pick', [start, end])
      }
    },
    {
      text: '最近一年',
      onClick (picker) {
        const end = new Date()
        const start = new Date()
        start.setMonth(start.getMonth() - 12)
        picker.$emit('pick', [start, end])
      }
    }
  ]
}

export const chunk = (array, size) => {
  if (!array.length || size < 1) return [];
  let result = [];
  array.forEach((item, index) => {
     const rowSize = Math.floor(index / size);
     if(!(result[rowSize] instanceof Array)){
        result[rowSize] = [];
     }
     result[rowSize].push(item);
   })
   return result;
}

export const fromEntries = arr => {
  if (Object.prototype.toString.call(arr) === '[object Map]') {
    let result = {};
    for (const key of arr.keys()) {
      result[key] = arr.get(key);
    }

    return result;
  }

  if(Array.isArray(arr)){
    let result = {}
    arr.map(([key,value]) => {
      result[key] =  value
    })

    return result
  }
  throw 'Uncaught TypeError: argument is not iterable';
}