var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "t-date-picker" },
    [
      _c(
        "el-date-picker",
        _vm._b(
          {
            attrs: { type: _vm.type, "picker-options": _vm.dateOptions },
            on: { change: _vm.dateChange },
            model: {
              value: _vm.time,
              callback: function ($$v) {
                _vm.time = $$v
              },
              expression: "time",
            },
          },
          "el-date-picker",
          _vm.attrsBind,
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }