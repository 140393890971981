/**
 * 格式化日期
 * @prama t 时间戳
 * @return str MM-dd HH:mm
 */
export function formatDate(t) {
    t = t || Date.now();
    let time = new Date(t);
    let str = time.getMonth() < 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
    str += '-';
    str += time.getDate() < 10 ? '0' + time.getDate() : time.getDate();
    str += ' ';
    str += time.getHours();
    str += ':';
    str += time.getMinutes() < 10 ? '0' + time.getMinutes() : time.getMinutes();
    return str;
};

/**
 * 距当前时间点的时长
 * @prama time 13位时间戳
 * @return str x秒 / x分钟 / x小时
 */
export function formateTime(time) {
    const second = 1000;
    const minute = second * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const now = new Date().getTime();
    const diffValue = now - time;

    // 计算差异时间的量级
    const secondC = diffValue / second;
    const minC = diffValue / minute;
    const hourC = diffValue / hour;
    const dayC = diffValue / day;

    if (dayC >= 1) {
        return parseInt(dayC) + "天";
    } else if (hourC >= 1) {
        return parseInt(hourC) + "小时";
    } else if (minC >= 1) {
        return parseInt(minC) + "分钟";
    } else if (secondC >= 1) {
        return parseInt(secondC) + "秒";
    } else {
        return '0秒';
    }
}

/**
 * 将给定的数字转换为格式化的金额字符串。
 * 
 * @param {number} numberToFormat - 要格式化的数字（浮点数）。
 * @param {string} [currencySymbol='$'] - 货币符号，默认为美元符号 '$'。
 * @returns {string} 格式化后的金额字符串，包含货币符号和两位小数。
 */
export function formatMoney(numberToFormat, currencySymbol = '$') {
    return currencySymbol + numberToFormat.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

/**
 * 计算两个日期之间相差的天数。
 * 
 * @param {Date} startDate - 起始日期。
 * @param {Date} endDate - 结束日期。
 * @returns {number} 两个日期之间相差的天数。如果 endDate 早于 startDate，则返回负数。
 */
export function calculateDayDifference(startDate, endDate) {
    const oneDay = 24 * 60 * 60 * 1000; // 一天的毫秒数
    const start = new Date(startDate).getTime();
    const end = new Date(endDate).getTime();
    return Math.floor((end - start) / oneDay);
}