var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "t_module_form",
      style: { marginBottom: _vm.footer !== null ? "60px" : "" },
    },
    [
      _c(
        "div",
        { staticClass: "scroll_wrap" },
        [
          _vm.title || _vm.titleSlot
            ? _c("el-page-header", {
                class: {
                  noContent: !_vm.isShow("extra") && !_vm.subTitle,
                  isShowBack: _vm.isShowBack,
                },
                attrs: { title: _vm.title },
                on: { back: _vm.back },
                scopedSlots: _vm._u(
                  [
                    _vm.titleSlot
                      ? {
                          key: "title",
                          fn: function () {
                            return [_vm._t("title")]
                          },
                          proxy: true,
                        }
                      : null,
                    {
                      key: "content",
                      fn: function () {
                        return [
                          _c("div", { staticClass: "sub_title" }, [
                            _vm._v(_vm._s(_vm.subTitle)),
                          ]),
                          _c(
                            "div",
                            { staticClass: "extra" },
                            [_vm._t("extra")],
                            2
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            : _vm._e(),
          _vm.handleType === "edit"
            ? _c(
                "module-form",
                _vm._g(
                  _vm._b(
                    {
                      ref: "tForm",
                      scopedSlots: _vm._u(
                        [
                          _vm._l(_vm.$scopedSlots, function (index, name) {
                            return {
                              key: name,
                              fn: function (data) {
                                return [_vm._t(name, null, null, data)]
                              },
                            }
                          }),
                        ],
                        null,
                        true
                      ),
                    },
                    "module-form",
                    _vm.$attrs,
                    false
                  ),
                  _vm.$listeners
                )
              )
            : _c(
                "module-detail",
                _vm._b(
                  {
                    scopedSlots: _vm._u(
                      [
                        _vm._l(_vm.$scopedSlots, function (index, name) {
                          return {
                            key: name,
                            fn: function (data) {
                              return [_vm._t(name, null, null, data)]
                            },
                          }
                        }),
                      ],
                      null,
                      true
                    ),
                  },
                  "module-detail",
                  _vm.$attrs,
                  false
                )
              ),
          _vm.tabs
            ? _c(
                "div",
                {
                  staticClass: "tabs",
                  style: {
                    "margin-top": _vm.isTabMargin ? _vm.tabMarginNum + "px" : 0,
                  },
                },
                [
                  _vm.tabs && _vm.tabs.length > 1
                    ? _c(
                        "el-tabs",
                        {
                          on: {
                            "tab-click": function (tab, event) {
                              return _vm.$emit("tabsChange", tab, event)
                            },
                          },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        _vm._l(_vm.tabs, function (tab) {
                          return _c(
                            "el-tab-pane",
                            {
                              key: tab.key,
                              attrs: { name: tab.key, label: tab.title },
                            },
                            [_vm._t(tab.key)],
                            2
                          )
                        }),
                        1
                      )
                    : _vm._t(_vm.tabs && _vm.tabs[0].key),
                ],
                2
              )
            : _vm._e(),
          _vm._t("default"),
        ],
        2
      ),
      _vm.footer !== null
        ? _c(
            "footer",
            { staticClass: "handle_wrap" },
            [
              _vm._t("footer"),
              !_vm.$slots.footer
                ? _c(
                    "div",
                    [
                      _c("el-button", { on: { click: _vm.back } }, [
                        _vm._v("取消"),
                      ]),
                      _vm.handleType === "edit"
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "primary", loading: _vm.loading },
                              on: { click: _vm.saveHandle },
                            },
                            [_vm._v(_vm._s(_vm.btnTxt))]
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }