var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          ref: "select",
          style: { width: _vm.width || "100%" },
          attrs: {
            multiple: _vm.multiple,
            "filter-method": _vm.dataFilter,
            "popper-class": "t-tree-select",
          },
          on: { "remove-tag": _vm.removeTag, clear: _vm.clearAll },
          model: {
            value: _vm.selectValue,
            callback: function ($$v) {
              _vm.selectValue = $$v
            },
            expression: "selectValue",
          },
        },
        "el-select",
        _vm.attrs,
        false
      ),
      _vm.$listeners
    ),
    [
      _c(
        "el-option",
        {
          staticClass: "option-style",
          attrs: { disabled: "" },
          model: {
            value: _vm.selectTree,
            callback: function ($$v) {
              _vm.selectTree = $$v
            },
            expression: "selectTree",
          },
        },
        [
          _vm.multiple && _vm.checkBoxBtn
            ? _c(
                "div",
                { staticClass: "check-box" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: { click: _vm.handlecheckAll },
                    },
                    [_vm._v("全选")]
                  ),
                  _c(
                    "el-button",
                    { attrs: { type: "text" }, on: { click: _vm.handleReset } },
                    [_vm._v("清空")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: { click: _vm.handleReverseCheck },
                    },
                    [_vm._v("反选")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-tree",
            _vm._g(
              _vm._b(
                {
                  ref: "treeNode",
                  staticClass: "tree-style",
                  attrs: {
                    data: _vm.options,
                    props: _vm.treeProps,
                    "show-checkbox": _vm.multiple,
                    "node-key": _vm.treeProps.value,
                    "filter-node-method": _vm.filterNode,
                    "default-checked-keys": _vm.defaultValue,
                    "current-node-key": _vm.currentKey,
                  },
                  on: {
                    "node-click": _vm.handleTreeClick,
                    "check-change": _vm.handleNodeChange,
                  },
                },
                "el-tree",
                _vm.treeAttrs,
                false
              ),
              _vm.$listeners
            )
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }