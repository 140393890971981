import request from '@/utils/request'

export function get(url, query) {
  return request({
    url: url,
    method: 'get',
    params: query
  })
}

export function post(url, data) {
  return request({
    url: url,
    method: 'post',
    data: data
  })
}

export function del(url, ids) {
  return request({
    url: url + ids.join(","),
    method: 'delete'
  })
}

export function put(url, data) {
  return request({
    url: url,
    method: 'put',
    data: data
  })
}

export function download(url, params) {
  return request({
    url: url,
    method: 'get',
    params: params || "",
    responseType: 'blob'
  })
}
